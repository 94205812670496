<template>
  <v-container class="processing-container white mt-12 pa-16 text-center">
    <div class="text-h6 title">
      {{ $t("PROCESS.TEXT.HEADER") }}
    </div>
    <div class="caption title font-weight-light">
      {{ $t("PROCESS.TEXT.DESCRIPTION") }}
    </div>
    <v-progress-linear
      v-if="status.current !== status.total"
      class="mt-2"
      indeterminate
    />
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import * as config from "./../../../config.json";

export default {
  name: "ProcessingData",
  data() {
    return {
      status: {
        label: null,
        current: 0,
        total: 0
      },
      firstLoading: false,
      interval: null
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    }
  },
  created() {
    this.firstLoading = true;
    this.getProcessingStatus(this.projectId)
      .then(response => {
        this.status = response.data;

        if (this.status.current !== this.status.total) {
          this.interval = setInterval(
            this.setStatus,
            config.REFRESH_STATUS_INTERVAL * 1000
          );
        }
      })
      .catch(error => {
        console.log(error);
        this.$notifier.notify({
          message: this.$t("COMMON.SOMETHING_WENT_WRONG"),
          color: "error"
        });
      })
      .finally(() => {
        this.firstLoading = false;
      });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions({
      getProcessingStatus: "api/project/getProcessingStatus",
      updateProjectStatus: "api/project/updateProjectStatus"
    }),
    setStatus() {
      this.getProcessingStatus(this.projectId)
        .then(async response => {
          this.status = response.data;
          if (this.status.current === this.status.total) {
            clearInterval(this.interval);
            await this.updateProjectStatus(this.projectId);
            await this.$router.push({
              name: "DataPreview",
              params: { projectId: this.$route.params.projectId }
            });
          }
        })
        .catch(error => {
          clearInterval(this.interval);
          console.log(error);
          this.$notifier.notify({
            message: this.$t("COMMON.SOMETHING_WENT_WRONG"),
            color: "error"
          });
        });
    }
  }
};
</script>

<style lang="scss">
.processing-container {
  max-width: 1200px;
}
</style>
